<template>
    <main>
        <HeaderTab :title="$t('global.contact_support')" />
        <div id="content">
            <div class="box mx-2">
               <h1>{{ $t('support.ticket') }}</h1>
               {{ $t('support.technique_text') }}<a target=”_blank” href=https://equideclic.atlassian.net/servicedesk/customer/portal/2/create/94> {{ $t('support.cliquant_ici') }}</a>.
               {{ $t('support.bug_text') }}<a target=”_blank” href=https://equideclic.atlassian.net/servicedesk/customer/portal/2/create/95> {{ $t('support.cliquant_ici') }}</a>.
            </div>
            <div class="box mx-2">
               <h1>{{ $t('support.email') }}</h1>
               {{ $t('support.email_text') }}:<a href=mailto:support@equideclic.fr> {{ $t('support.email_value') }}</a>
            </div>
            <div class="box mx-2" v-if="hasAccessToPhone">
                <h1>{{ $t('support.telephone') }}</h1>
                {{ $t('support.telephone_text') }}: <span class="h6">{{ $t('support.telephone_value') }}</span>

            </div>
            <div class="box mx-2" v-if="hasAccessToPhone">
                <h1>{{ $t('support.calendrier') }}</h1>
                <div class="row my-4 ml-2 justify-content-center" >
                    <h5 class="mt-2">{{ $t('support.rendez_vous') }} :</h5>
                    <button @click="showCalendar1" class="btn btn-md mx-2" :class="Calendar1 ? 'btn-primary' : 'btn-secondary'" >Nadège Duprez</button>
                    <button @click="showCalendar2" class="btn btn-md mx-2" :class="Calendar2 ? 'btn-primary' : 'btn-secondary'" >Anne-Laure Valognes</button>
                </div>
                <div style="margin: 0 auto" class="d-flex justify-content-center">
                    <iframe v-if="Calendar1" :src="$t('support.agenda_nadege')" width="800px" height="850px" ></iframe>
                    <iframe v-if="Calendar2"  :src="$t('support.agenda_annelaure')" width="800px" height="850px" ></iframe>
                </div>
            </div>
        </div>
    </main>
</template>

<script>

import User from "@/mixins/User.js"
export default {
    name: 'Support',
    mixins: [User],
    data: () => ({
        gamme: {},
        Calendar1: true,
        Calendar2: false,
    }),
    methods: {
        showCalendar1(){
            this.Calendar2 = false
            this.Calendar1 = true
        },
        showCalendar2(){
            this.Calendar1 = false
            this.Calendar2 = true
        }
    },
    computed: {
        //Si l'utilisateur n'est pas un MVP, premium ou premium+
        hasAccessToPhone() {

            return this.$store.state.userAccess.hasActTypeAccess
        },
	},
    components: {
        HeaderTab: () => import('@/components/HeaderTab'),
    }
}
</script>